require('./bootstrap');
require('./filter');
window.$ = window.jQuery = require('jquery');

const languageSelect = document.getElementById('language');

if (languageSelect) {
    languageSelect.addEventListener('change', (val) => {
        let url = new URL(window.location.href);

        console.log(val);
        url.searchParams.set('lang', languageSelect.value);

        window.location.href = url;
    })
}

if (document.querySelector('input[type="file"]')) {
    document.querySelector('input[type="file"]').addEventListener('change', (event) => {
        let filename = event.target.files[0].name;
        document.querySelector('.custom-file-label').innerHTML = filename;
    })
}

$(function() {
    $('[data-toggle="tooltip"]').tooltip()
})
