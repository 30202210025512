(function(document) {
	'use strict';

	var LightTableFilter = (function(Arr) {

		var _textInput;
        var _radioButton;

		function _onInputEvent(e) {
			_textInput = e.target;
			var tables = document.getElementsByClassName(_textInput.getAttribute('data-table'));
			Arr.forEach.call(tables, function(table) {
				Arr.forEach.call(table.tBodies, function(tbody) {
					Arr.forEach.call(tbody.rows, _filter);
				});
			});
		}

        function _onRadioChange(e) {
            console.log(e.target);
            _radioButton = e.target;
            var tables = document.getElementsByClassName(_radioButton.getAttribute('data-table'));
            Arr.forEach.call(tables, function(table) {
				Arr.forEach.call(table.tBodies, function(tbody) {
					Arr.forEach.call(tbody.rows, _filter);
				});
			});
        }

		function _filter(row) {

            var textFound = true;
            var statusFound = true;
            
			if(_textInput) {
                var text = row.textContent.toLowerCase(), val = _textInput.value.toLowerCase();
                textFound = text.indexOf(val) === -1 ? false : true;
            }

            if(_radioButton && _radioButton.value != "all") {
                statusFound = row.getAttribute('data-status') == _radioButton.value;
            }
            
            row.style.display = textFound && statusFound ? 'table-row' : 'none';
		}

		return {
			init: function() {
				var inputs = document.getElementsByClassName('light-table-filter');
                var radios = document.getElementsByClassName('light-table-radio');

				Arr.forEach.call(inputs, function(input) {
					input.oninput = _onInputEvent;
				});

                Arr.forEach.call(radios, function(input) {
					input.onchange = _onRadioChange;
				});
			}
		};
	})(Array.prototype);

	document.addEventListener('readystatechange', function() {
		if (document.readyState === 'complete') {
			LightTableFilter.init();
		}
	});

})(document);